.form-row {
  margin-top: 1rem;
}

.form-row label {
  color: #898989;
}

.form-row input {
  width: 100%;
}

.Breadcrumbs {
  border: 1px solid #999;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 0.25rem;
}

.Breadcrumbs a {
  color: #333;
}
.Breadcrumbs a:visited {
  color: #999;
}
.Breadcrumbs a:not(:first-child)::before {
  content: ' << ';
}

.wizard-container {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 10px;
}

.ui-steps.steps-custom {
  margin-bottom: 30px;
}

.ui-steps .ui-steps-item {
  width: 33%;
}

.ui-steps.steps-custom-2 .ui-steps-item {
  width: 50%;
}

.ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
  height: 10px;
  padding: 0 1em;
  overflow: visible;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-number {
  background-color: #0081c2;
  color: #ffffff;
  display: inline-block;
  width: 36px;
  border-radius: 50%;
  margin-top: -14px;
  margin-bottom: 10px;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-title {
  color: #555555;
}

span.pill {
  padding: 3px;
  border-radius: 3px;
  color: white;
  background-color: #ccc;
}

span.pill.pill-SUCCESS {
  background-color: Green;
}
span.pill.pill-RESCHEDULED {
  background-color: GoldenRod;
}
span.pill.pill-FAILURE {
  background-color: FireBrick;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-muted {
  color: #999;
}

.text-white {
  color: white;
}
.bg-success {
  background-color: #28a745;
}
.bg-danger {
  background-color: #dc3545;
}
.pl-3 {
  padding-left: 15px;
}
.p-3 {
  padding: 15px;
}

code,
samp,
kbd {
  font-family: 'Courier New', Courier, monospace, sans-serif;
  text-align: left;
  color: #555;
}
pre code {
  line-height: 1.6em;
  font-size: 11px;
}
pre {
  padding: 0.1em 0.5em 0.3em 0.7em;
  border: 1px solid #ccc;
  border-left: 11px solid #ccc;
  border-color: #ccc;
  background-color: #fcfcfc;
  max-width: 65vw;
  overflow: scroll;
}
/* target IE7 and IE6 */
*:first-child + html pre {
  padding-bottom: 2em;
  overflow-y: hidden;
  overflow: visible;
  overflow-x: auto;
}
* html pre {
  padding-bottom: 2em;
  overflow: visible;
  overflow-x: auto;
}
